import { gsap } from 'gsap'

let body = null

function pageOut (data, resolve) {
  // console.log('page OUT')

  body = data.current.container.querySelector('[data-page-body]')

  gsap.to(body, {
    autoAlpha: 0,
    duration: 0.5,
    onComplete: function () {
      resolve()
    }
  })
}

function pageIn (data) {
  // console.log('page IN')

  body = data.next.container.querySelector('[data-page-body]')

  // hide initially
  gsap.set(body, { autoAlpha: 0 })

  gsap.to(body, {
    autoAlpha: 1,
    duration: 1,
    delay: 1
  })
}

export { pageIn, pageOut }
