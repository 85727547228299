export default function categoryImages (data) {
  const imgWrap = data.next.container.querySelector('[data-cat-images]')

  if (imgWrap) {
    imgWrap.addEventListener('click', function (e) {
      if (window.innerWidth < 900) {
        if (imgWrap.classList.contains('is-active')) {
          imgWrap.classList.remove('is-active')
        } else {
          imgWrap.classList.add('is-active')
        }
      }
    })

    window.addEventListener('resize', () => {
      if (window.innerWidth >= 900) {
        imgWrap.classList.remove('is-active')
      }
    })
  }
}
