import { gsap } from 'gsap'

function listingHover (data) {
  const wrap = data.next.container.querySelector('[data-listing-wrap]')
  const img = data.next.container.querySelector('[data-listing-img]')
  let listingItems
  let timer
  let imgSrc
  let currSrc
  let target
  let tl

  if (wrap) {
    listingItems = wrap.querySelectorAll('.listing__item:not(.listing__item--none)')

    // Update img tag to current hovered item's img src
    listingItems.forEach(item => {
      item.addEventListener('mouseenter', showCurrentImg)

      item.firstElementChild.addEventListener('click', function (e) {
        // Remove the hover event when an item is selected so the chosen image stays
        item.removeEventListener('mouseEnter', showCurrentImg)

        // Set image on click so it matches the next page for sure
        imgSrc = e.target.getAttribute('data-listing-item')
        e.target.classList.add('is-active')
        showImage(imgSrc)
      })
    })
  }

  function showCurrentImg (e) {
    window.clearTimeout(timer)
    target = e.target.querySelector('a')
    imgSrc = target.getAttribute('data-listing-item')

    listingItems.forEach(item => {
      item.firstElementChild.classList.remove('is-active')
    })
    target.classList.add('is-active')

    timer = window.setTimeout(function () {
      showImage(imgSrc)
    }, 400)
  }

  function showImage (imgSrc) {
    tl = gsap.timeline()

    // Get previous image so we don't do anything if they match
    currSrc = img.src

    // Update the image src if it's a new image
    if (currSrc != imgSrc) {
      tl
        .to(img, { autoAlpha: 0, duration: 0.2 })
        .add(() => { img.src = imgSrc })
        .to(img, { autoAlpha: 1, duration: 0.35 }, '+=0.05')
    }
  }
}

// Setting the current catalogue item on the body so we know how to start the listing on transition
function setCurrCatalogue (data) {
  let body = null
  let currTitle = null

  if (data.next.container.querySelector('[data-page-body]').hasAttribute('data-item-id')) {
    body = data.next.container.querySelector('[data-page-body]')
    currTitle = body.getAttribute('data-item-id')
    document.querySelector('body').setAttribute('data-current-cat-item', currTitle)
  } else {
    // console.log('no need to set the title, not going to a detail page')
  }
}

export { listingHover, setCurrCatalogue }
