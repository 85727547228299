export default function loadScript(url, callback, loaded) {
    let selector = 'script[src*="' + url + '"]';
    if (!document.querySelector(selector)) {
        let script = document.createElement("script");
        script.type = 'text/javascript';

        if (script.readyState) {  //IE
            script.onreadystatechange = function () {
                if (script.readyState === 'loaded' ||
                    script.readyState === 'complete') {
                    script.onreadystatechange = null;
                    if (callback) {
                        callback();
                    }
                }
            };
        } else {  //Others
            script.onload = function () {
                if (callback) {
                    callback();
                }
            };
        }

        script.src = url;
        document.body.appendChild(script);
    } else if (loaded) {
        loaded();
    }
  }