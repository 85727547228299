/*
    Barba.js page transitions
*/

import barba from '@barba/core'
import barbaPrefetch from '@barba/prefetch'
import moduleJS from '../modules/_index'
import defaultJS from '../default/_index'

import { transitionsIn, transitionsOut } from './transitions/_index'

export default function pageTransitions () {
  const body = document.querySelector('body')
  const menuItems = document.querySelectorAll('.menu__link')
  const icon = document.querySelector('[data-icon]')

  const overflowHidden = 'overflow-hidden'

  let nextHandle

  // Prefetch
  barba.use(barbaPrefetch)

  barba.hooks.leave((data) => {
    // this hook will be called for each transitions
  })

  barba.init({
    transitions: [{
      name: 'default',

      beforeOnce (data) {
        // Move user to top of page and stop scrolling for the animations
        setTimeout(function () {
          window.scrollTo(0, 0)
        }, 10)
        body.classList.remove(overflowHidden)
      },

      once (data) {
        nextHandle = data.next.container.getAttribute('data-handle')
        if (nextHandle == '') {
          document.querySelector('body').setAttribute('data-current-template', 'front-page')
        } else {
          document.querySelector('body').setAttribute('data-current-template', nextHandle)
        }

        transitionsIn(data)
        defaultJS() // Init core JS
        moduleJS(data) // Init page-level modules
      },

      afterOnce (data) {
        // Re-enable scrolling
        body.classList.remove(overflowHidden)
      },

      beforeLeave (data) {
        // Stop scrolling for animations
        body.classList.add(overflowHidden)
      },

      leave (data) {
        // Run out transition
        return new Promise(resolve => {
          transitionsOut(data, resolve)
        })
      },

      beforeEnter (data) {
        // Moving scroll to top before new page transitions in
        window.scrollTo(0, 0)

        nextHandle = data.next.container.getAttribute('data-handle')

        if (nextHandle == '') {
          document.querySelector('body').setAttribute('data-current-template', 'front-page')
        } else {
          document.querySelector('body').setAttribute('data-current-template', nextHandle)
        }

        menuItems.forEach(item => {
          if (nextHandle == item.getAttribute('data-nav-handle')) {
            item.classList.add('is-active')
          } else {
            item.classList.remove('is-active')
          }
        })
      },

      enter (data) {
        if (data.next.container.firstElementChild.getAttribute('data-template') == 'front-page') {
          icon.classList.add('text-white')
        } else {
          icon.classList.remove('text-white')
        }

        // In transitions
        transitionsIn(data)
      },

      afterEnter (data) {
        // Re-init page JS
        moduleJS(data)

        // Enabling scrolling
        body.classList.remove(overflowHidden)
      }
    }
    ]
  })

  // Trigger page analytics
  // barba.hooks.after(() => {
  //     ga('set', 'page', window.location.pathname)
  //     ga('send', 'pageview')
  // })
}
