import { gsap } from 'gsap'

let visibleItems = null
let visibleLength = null
let itemsNone = null
let items = null
let tl = null
let img = null
const currImg = null

function toggleFilter (data) {
  const toggle = data.next.container.querySelector('[data-reveal-filter]')
  const filters = data.next.container.querySelector('[data-filters]')
  const activeClass = 'is-active'

  if (toggle) {
    toggle.addEventListener('click', function (e) {
      if (filters.classList.contains(activeClass)) {
        this.querySelector('span').innerHTML = '+'
        filters.classList.remove(activeClass)
      } else {
        this.querySelector('span').innerHTML = '-'
        filters.classList.add(activeClass)
      }
    })
  }
}

function filterBy (data) {
  const wrap = data.next.container.querySelector('[data-listing-wrap]')
  const filterItems = data.next.container.querySelectorAll('[data-filter-item]')
  const filterSearch = data.next.container.querySelector('[data-filter-search]')
  const activeClass = 'is-active'

  let target = null

  // Filter item click events
  if (filterItems.length) {
    filterItems.forEach(item => {
      item.addEventListener('click', function (e) {
        target = item.getAttribute('data-filter-item')

        // Resetting search value on filter use
        filterSearch.value = ''

        filterItems.forEach(e => {
          if (e.getAttribute('data-filter-item') == target) {
            e.classList.add(activeClass)
          } else {
            e.classList.remove(activeClass)
          }
        })

        items = wrap.querySelectorAll('[data-listing-category]')

        if (target == 'all') {
          items.forEach(item => {
            if (item.classList.contains('is-not-on-show')) {
              showItem(item)
              item.classList.remove('is-not-on-show')
            }
          })
        } else {
          items.forEach(item => {
            if (item.getAttribute('data-listing-category') == target) {
              if (item.classList.contains('is-not-on-show')) {
                showItem(item)
                item.classList.remove('is-not-on-show')
              } else {
                // console.log('already on show')
              }
            } else {
              item.classList.add('is-not-on-show')
              hideItem(item)
            }
          })
        }

        highlightFirstItem(wrap, data)

        // Show/hide the 'no items' if required
        checkLength(wrap, data)
      })
    })
  }
}

function searchItems (data) {
  const wrap = data.next.container.querySelector('[data-listing-wrap]')
  const filterSearch = data.next.container.querySelector('[data-filter-search]')
  const hiddenClass = 'is-hidden'

  let searchTerm = null
  let currFilter = null
  let listItems = null

  // Search field handler
  if (filterSearch) {
    // Resetting value on page load
    filterSearch.value = ''

    filterSearch.addEventListener('focus', (e) => {
      if (filterSearch.value.length == 0) {
        data.next.container.querySelector('[data-filter-item=all]').click()
      }
    })

    filterSearch.addEventListener('keyup', function (e) {
      searchTerm = e.target.value.toLowerCase().trim()
      currFilter = wrap.getAttribute('data-listing-filtered-by')
      listItems = wrap.querySelectorAll('[data-listing-category]')

      listItems.forEach(item => {
        if (item.textContent.toLowerCase().includes(searchTerm)) {
          if (item.classList.contains('is-not-on-show')) {
            showItem(item)
            item.classList.remove('is-not-on-show')
          } else {
            console.log('already on show')
          }
        } else {
          item.classList.add('is-not-on-show')
          hideItem(item)
        }
      })

      highlightFirstItem(wrap, data)
      checkLength(wrap, data)
    })
  }
}

function hideItem (item) {
  gsap.to(item, 0.5, {
    height: 0,
    ease: 'expo.inOut'
  }, 0)
}

function showItem (item) {
  tl = gsap.timeline()

  gsap.set(item, {
    height: 'auto',
    autoAlpha: 1
  })

  tl
    .from(item, {
      height: 0,
      autoAlpha: 0,
      duration: 0.5,
      ease: 'expo.inOut'
    }, 0)
    .set(item, { clearProps: 'height, visibility, opacity' })
}

function highlightFirstItem (wrap, data) {
  img = data.next.container.querySelector('[data-listing-img]')
  visibleItems = wrap.querySelectorAll('[data-listing-category]:not(.is-not-on-show)')

  visibleItems.forEach(item => {
    item.firstElementChild.classList.remove('is-active')
  })

  if (visibleItems.length) {
    visibleItems[0].firstElementChild.classList.add('is-active')
    const imgSrc = visibleItems[0].firstElementChild.getAttribute('data-listing-item')
    const currSrc = img.src

    tl = gsap.timeline()

    // Update the image src if it's a new image
    if (currSrc != imgSrc) {
      tl
        .to(img, { autoAlpha: 0, duration: 0.2 })
        .add(() => { img.src = imgSrc })
        .to(img, { autoAlpha: 1, duration: 0.35 }, '+=0.05')
    }
  }
}

// Show/hide the 'no items' if required
function checkLength (wrap, data) {
  itemsNone = data.next.container.querySelector('[data-listing-none]')
  img = data.next.container.querySelector('[data-listing-img]')
  visibleItems = wrap.querySelectorAll('[data-listing-category]:not(.is-not-on-show)')
  visibleLength = visibleItems.length
  tl = gsap.timeline()

  if (visibleLength == 0) {
    itemsNone.classList.add('is-visible')
    tl
      .to(img, { autoAlpha: 0, duration: 0.2 })
      .add(() => { img.src = '' })
  } else {
    itemsNone.classList.remove('is-visible')
  }
}

// Mini function to check if an item is visible in the DOM
function isVisible (el) {
  return el.offsetWidth > 0 ||
        el.offsetHeight > 0 ||
        el.getClientRects().length > 0
}

export { toggleFilter, filterBy, searchItems }
