export default function setLogoColor (data) {
  const logoColor = data.next.container.querySelector('[data-page-body]').getAttribute('data-logo-color')
  const logo = document.querySelector('[data-icon]')

  if (logoColor) {
    logo.className = `sidebar__icon text-${logoColor}`
  } else {
    logo.className = 'sidebar__icon text-black'
  }
}
