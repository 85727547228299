import Cookies from 'js-cookie'

export default function cookieBanner () {
  const banner = document.querySelector('[data-cookie-banner]')
  const cookieAccept = document.querySelector('[data-cookie-accept]')
  const activeClass = 'is-active'

  if (banner) {
    if (Cookies.get('stp-cookies-accept')) {
      banner.remove()
    } else {
      banner.classList.add(activeClass)
      cookieAccept.addEventListener('click', acceptCookies.bind(this))
    }

    function acceptCookies () {
      Cookies.set('stp-cookies-accept', true, { expires: 730 })
      banner.classList.remove(activeClass)
    }

    function resetCookie () {
      Cookies.remove('stp-cookies-accept')
    }
  }
}
