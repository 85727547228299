import { gsap } from 'gsap'

const activeClass = 'is-active'
let tl = null
const body = null
let content = null
let img = null

const mq900 = window.matchMedia('(max-width: 900px)')

function catalogueDetailOut (data, resolve) {
  // console.log('catalogue detail OUT')

  content = data.current.container.querySelector('[data-detail-content]')
  img = data.current.container.querySelector('[data-cat-images]')

  tl = gsap.timeline({
    onComplete: function () {
      resolve()
    }
  })

  if (mq900.matches) {
    gsap.to(img, {
      autoAlpha: 0,
      duration: 1
    })
  }

  tl
    .to(content, {
      autoAlpha: 0,
      duration: 1
    })
}

function catalogueDetailIn (data) {
  // console.log('catalogue detail IN')

  content = data.next.container.querySelector('[data-detail-content]')
  img = data.next.container.querySelector('[data-cat-images]')

  tl = gsap.timeline()
  gsap.set(content, { autoAlpha: 0 })

  if (mq900.matches) {
    // console.log('is below 900px')
    gsap.set(img, { autoAlpha: 0 })
    gsap.to(img, {
      autoAlpha: 1,
      duration: 1,
      delay: 0.2
    })
  } else {
    // console.log('is above 900px')
  }

  gsap.to(content, {
    autoAlpha: 1,
    duration: 0.6,
    delay: 0.2
  })
}

export { catalogueDetailOut, catalogueDetailIn }
