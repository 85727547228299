import { gsap } from 'gsap'
import { pageOut, pageIn } from './page'
import { catalogueListingOut, catalogueListingIn } from './catalogueListing'
import { catalogueDetailOut, catalogueDetailIn } from './catalogueDetail'

let currTemplate
let currContainer
let nextTemplate
let nextContainer

let currTitle
let main
let newTitle

const body = document.querySelector('body')
const sidebarTitle = body.querySelector('[data-sidebar-title]')

function transitionsOut (data, resolve) {
  currContainer = data.current.container
  currTemplate = data.current.container.firstElementChild.getAttribute('data-template')
  nextContainer = data.next.container
  nextTemplate = data.next.container.firstElementChild.getAttribute('data-template')

  // checking if I need to update the sidebar
  main = data.next.container.querySelector('[data-page-body]')
  newTitle = main.getAttribute('data-page-title')
  currTitle = sidebarTitle.textContent

  if (currTitle != newTitle) {
    gsap.to(sidebarTitle, {
      autoAlpha: 0,
      duration: 0.4
    })
  }

  // Listing to detail
  if (currTemplate == 'catalogue-listing' && nextTemplate == 'catalogue-detail') {
    catalogueListingOut(data, resolve)

    // Detail to listing
  } else if (currTemplate == 'catalogue-detail' && nextTemplate == 'catalogue-listing') {
    catalogueDetailOut(data, resolve)

    // Generic fade
  } else {
    pageOut(data, resolve)
  }
}

function transitionsIn (data) {
  if (data.current.container) {
    currContainer = data.current.container
    currTemplate = data.current.container.firstElementChild.getAttribute('data-template')
  }

  nextContainer = data.next.container
  nextTemplate = data.next.container.firstElementChild.getAttribute('data-template')

  if (data.current.container) {
    // Listing to detail
    if (currTemplate == 'catalogue-listing' && nextTemplate == 'catalogue-detail') {
      // console.log('LISTING TO DETAIL')
      catalogueDetailIn(data)

      // Detail to listing
    } else if (currTemplate == 'catalogue-detail' && nextTemplate == 'catalogue-listing') {
      // console.log('DETAIL TO LISTING')
      catalogueListingIn(data)

      // Generic fade
    } else {
      pageIn(data)
    }
  } else {
    pageIn(data)
  }

  const tl = gsap.timeline()
  main = data.next.container.querySelector('[data-page-body]')
  newTitle = main.getAttribute('data-page-title')
  currTitle = sidebarTitle.textContent

  if (currTitle != newTitle) {
    tl
      .add(function () {
        sidebarTitle.innerHTML = newTitle
      })
      .to(sidebarTitle, {
        autoAlpha: 1,
        duration: 0.4,
        delay: 1
      })
  }
}

export { transitionsIn, transitionsOut }
