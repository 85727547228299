import { listingHover, setCurrCatalogue } from './catalogue'
import { toggleFilter, filterBy, searchItems } from './catalogueFilter'
import categoryImages from './categoryImages'
import loadScript from './loadScript'

import overlay from './overlay'
import setLogoColor from './setLogoColor'

export default function modulesJS (data) {
  listingHover(data)
  setCurrCatalogue(data)
  toggleFilter(data)
  filterBy(data)
  searchItems(data)
  categoryImages(data)
  setLogoColor(data)
  overlay(data)
  if (data.next.container.querySelector('#barba-subscription-contact-form-flynt')) {
    loadScript('https://js.createsend1.com/javascript/copypastesubscribeformlogic.js')
  }
}
