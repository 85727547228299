import Plyr from 'plyr'

export default function overlay (data) {
  const overlayTriggers = data.next.container.querySelectorAll('[data-overlay-trigger]')
  const overlayClose = document.querySelector('[data-overlay-close]')
  const activeClass = 'is-active'
  const hiddenClass = 'is-hidden'
  const overlay = document.querySelector('[data-overlay]')
  const overlayPlayer = document.querySelector('[data-overlay-player]')
  const body = document.querySelector('body')
  const icon = document.querySelector('[data-icon]')
  let overlayType
  let video
  let plyrInstance

  if (overlay) {
    Array.from(overlayTriggers).forEach(element => {
      element.addEventListener('click', () => {
        overlayType = element.getAttribute('data-overlay-trigger')

        if (overlayType == 'video') {
          video = element.getAttribute('data-video')

          if (overlay.classList.contains(activeClass)) {
            closeOverlay(video)
          } else {
            openOverlay(video)
          }
        }
      })
    })

    overlayClose.addEventListener('click', function () {
      closeOverlay()
    })

    function openOverlay (video) {
      overlay.classList.add(activeClass)
      createPlayer(video)
      icon.classList.add(activeClass)
      body.classList.add(hiddenClass)
    }

    function closeOverlay () {
      overlay.classList.remove(activeClass)
      destroyPlayer()
      icon.classList.remove(activeClass)
      body.classList.remove(hiddenClass)
    }

    function createPlayer (video) {
      const playerMarkup =
                `<iframe
                    src="${video}"
                    allowfullscreen
                    allowtransparency
                    allow="autoplay"
                ></iframe>`

      overlayPlayer.querySelector('[data-player]').innerHTML = playerMarkup

      plyrInstance = new Plyr(document.querySelector('[data-player]'), {
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'fullscreen'
        ],
        clickToPlay: false
      })
    }

    function destroyPlayer () {
      // overlayPlayer.innerHTML = null
      plyrInstance.destroy()
      plyrInstance = null
    }
  }
}
