import pageTransitions from './barba/page-transitions'

pageTransitions()

// // Loaded once on initial page load
// import defaultJS from './default/_index.js'
// import moduleJS from './modules/_index.js'

// defaultJS()
// moduleJS()
