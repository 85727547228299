import { gsap } from 'gsap'

export default function setCatalogueImg (data) {
  const body = document.querySelector('body')
  const pageBody = data.next.container.querySelector('[data-page-body]')
  const listItems = data.next.container.querySelectorAll('[data-listing-item]')

  let itemId = null

  if (body.hasAttribute('data-current-cat-item')) {
    const currId = body.getAttribute('data-current-cat-item')

    listItems.forEach(item => {
      itemId = item.parentElement.getAttribute('data-item-id')

      if (itemId == currId) {
        item.parentElement.classList.add('is-clicked')
      }
    })

    gsap.set(pageBody.querySelectorAll('.listing__item:not(.is-clicked)'), {
      height: 0,
      autoAlpha: 0,
      ease: 'expo.inOut'
    })

    gsap.to(pageBody.querySelectorAll('.listing__item:not(.is-clicked)'), {
      height: 'auto',
      duration: 1,
      autoAlpha: 1,
      ease: 'expo.inOut'
    }, 0)
  }
}
