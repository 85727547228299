import { gsap } from 'gsap'
import setCatalogueImg from '../../modules/setCatalogueImg'

const activeClass = 'is-active'
let listItems = null
let tl = null
let body = null
let filters = null
let targetItem = null
let targetItemTitle = null

function catalogueListingOut (data, resolve) {
  // console.log('catalogue listing OUT')
  body = data.current.container.querySelector('[data-page-body]')
  filters = data.current.container.querySelector('[data-filter-wrap]')

  // Array of list items
  listItems = data.current.container.querySelectorAll('.listing__item')

  // Get clicked item
  targetItem = data.trigger.parentElement
  targetItemTitle = targetItem.getAttribute('data-item-id')
  targetItem.classList.add('is-clicked')

  // console.log(targetItemTitle)

  tl = gsap.timeline({
    onComplete: function () {
      resolve()
    }
  })

  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

  // Hide filters
  filters.querySelector('[data-reveal-filter] span').innerHTML = '-'
  filters.querySelector('[data-filters]').classList.remove(activeClass)

  gsap.to(filters, {
    autoAlpha: 0,
    duration: 1
  })

  tl
  // Hide non-clicked items
    .staggerTo(body.querySelectorAll('.listing__item:not(.is-clicked)'), 0.5, {
      height: 0,
      autoAlpha: 0,
      ease: 'expo.inOut'
    }, 0)
}

function catalogueListingIn (data) {
  // console.log('catalogue listing IN')

  const body = document.querySelector('body')
  const pageBody = data.next.container.querySelector('[data-page-body]')
  const listItems = data.next.container.querySelectorAll('[data-listing-item]')
  const img = data.next.container.querySelector('[data-listing-img]')

  let itemId = null
  let currImg = null
  tl = gsap.timeline()

  if (body.hasAttribute('data-current-cat-item')) {
    const currId = body.getAttribute('data-current-cat-item')

    listItems.forEach(item => {
      itemId = item.parentElement.getAttribute('data-item-id')

      item.parentElement.classList.remove('is-clicked')
      item.classList.remove('is-active')
      if (itemId == currId) {
        item.parentElement.classList.add('is-clicked')
        item.classList.add('is-active')
        currImg = item.getAttribute('data-listing-item')
        img.src = currImg
      }
    })

    // Setting the closed list items that need to animate open
    gsap.set(pageBody.querySelectorAll('.listing__item:not(.is-clicked)'), {
      height: 'auto',
      autoAlpha: 1
    })

    tl
      .from(pageBody.querySelectorAll('.listing__item:not(.is-clicked)'), {
        height: 0,
        autoAlpha: 0,
        duration: 0.5,
        ease: 'expo.inOut'
      }, 0)
      .set(pageBody.querySelectorAll('.listing__item:not(.is-clicked)'), { clearProps: 'height, visibility, opacity' })

    // Resetting
    listItems.forEach(item => {
      item.parentElement.classList.remove('is-clicked')
    })
  }
}

export { catalogueListingOut, catalogueListingIn }
