/*
    Menu. Not much more to say.
*/

export default function menu () {
  // Elements
  const hamburger = document.querySelector('[data-hamburger]')
  const menu = document.querySelector('[data-menu]')
  const body = document.querySelector('body')
  const icon = document.querySelector('[data-icon]')
  const main = document.querySelector('[data-main]')
  const menuItems = menu.querySelectorAll('a')

  // Classes
  const activeClass = 'is-active'
  const hiddenClass = 'is-hidden'

  hamburger.addEventListener('click', function () {
    if (hamburger.classList.contains(activeClass)) {
      closeMenu()
    } else {
      openMenu()
    }

    // Close the menu on click
    menuItems.forEach(item => {
      item.addEventListener('click', function () {
        closeMenu()
      })
    })

    function closeMenu () {
      hamburger.classList.remove(activeClass)
      hamburger.setAttribute('aria-expanded', false)
      hamburger.setAttribute('aria-label', 'Open main menu')
      menu.classList.remove(activeClass)
      main.classList.remove(activeClass)
      icon.classList.remove(activeClass)
      body.classList.remove(hiddenClass)
    }

    function openMenu () {
      hamburger.classList.add(activeClass)
      hamburger.setAttribute('aria-expanded', true)
      hamburger.setAttribute('aria-label', 'Close main menu')
      menu.classList.add(activeClass)
      main.classList.add(activeClass)
      icon.classList.add(activeClass)
      body.classList.add(hiddenClass)
    }
  })
}
